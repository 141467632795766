
import { Vue, Component } from "vue-property-decorator";
import { Field, Button } from "vant";

@Component({
  name: "SetUrl",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
  },
})
export default class SetUrl extends Vue {
  public value = "";
  public setUrl() {
    if(!this.value) return this.$toast.fail('url不能为空')
    sessionStorage.setItem("URL", this.value+"QianTaoWebService/");
  }
}
